import {Component, inject, OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {ApiConstant, CommonConstant, ErrorConstant, ModalConstant, UserConstant} from '@rcms/constants';
import {RoleNamePipe} from '@rcms/pipes';
import {CompanyService, RoleService, UserService} from '@rcms/services';
import {GlobalSignal} from '@rcms/states/signals';
import {ISelection, IUserCreateRequest, IUserResponse, LoopBackFilters} from '@rcms/types';
import {ApiUtil} from '@rcms/utils';
import {BaseCUDModalComponent} from '../base-cud-modal.component';

@Component({
  selector: 'app-user-create-modal',
  templateUrl: './user-create-modal.component.html',
  styleUrls: ['./user-create-modal.component.scss'],
})
export class UserCreateModalComponent extends BaseCUDModalComponent<IUserResponse, IUserCreateRequest>
  implements OnInit {
  private companyService = inject(CompanyService);
  private roleService = inject(RoleService);
  private userService = inject(UserService);

  companyDatasets: ISelection[] = [];
  isGettingCompanies = false;
  isGettingRoles = false;
  roleDatasets: ISelection[] = [];
  roleNamePipe = new RoleNamePipe();

  constructor() {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.getCompanyDatasets();
    this.getRoleDatasets();
  }

  doRequestObs(requestBody: IUserCreateRequest) {
    return this.userService.createUserObs(requestBody);
  }

  focusInputUsername() {
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.USERNAME_EXIST);
  }

  getCompanyDatasets(name?: string) {
    this.isGettingCompanies = true;
    this.companyDatasets = [];

    const filters = new LoopBackFilters().setLimit(ApiConstant.LOOPBACK_FILTER.LIMIT_MAX_SIZE);
    if (name) {
      filters.setWhere({name: ApiUtil.generateLoopbackFilterLike(name)});
    }
    this.companyService.getAllCompaniesObs(filters.serialize()).subscribe({
      next: (companies) => {
        this.isGettingCompanies = false;
        this.companyDatasets = companies.map(company => ({label: company.name, value: company.id}));
      },
      error: () => this.isGettingCompanies = false,
    });
  }

  getRequestParams(): IUserCreateRequest {
    return this.form.value;
  }

  getRoleDatasets() {
    this.isGettingRoles = true;
    this.roleDatasets = [];

    this.roleService.getAllRolesObs({}).subscribe({
      next: (roles) => {
        this.isGettingRoles = false;
        this.roleDatasets = roles.map(role => ({label: this.roleNamePipe.transform(role.name), value: role.id}));
      },
      error: () => this.isGettingRoles = false,
    });
  }

  handleRequestFailedSpecial() {
    this.setFeedbackCreateFailed(ModalConstant.ENTITY.USER);
  }

  handleRequestSuccessSpecial() {
    this.setFeedbackCreateSuccess(ModalConstant.ENTITY.USER);
    GlobalSignal.forceReloadDataFromModal.set(ModalConstant.NAME.USER_CREATE);
  }

  initForm() {
    this.form = this.fb.group({
      [CommonConstant.FIELD.USERNAME]: [
        '',
        [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required],
      ],
      [CommonConstant.FIELD.PASSWORD]: [
        '',
        [
          Validators.minLength(UserConstant.PASSWORD.LENGTH.MIN),
          Validators.maxLength(UserConstant.PASSWORD.LENGTH.MAX),
          Validators.required,
        ],
      ],
      [CommonConstant.FIELD.FULL_NAME]: [
        '',
        [Validators.maxLength(CommonConstant.LIMIT.LENGTH_256), Validators.required],
      ],
      [CommonConstant.FIELD.ROLE_ID]: ['', Validators.required],
      [CommonConstant.FIELD.COMPANY_ID]: ['', Validators.required],
      [CommonConstant.FIELD.ACTIVE]: [true],
    });
    this.onFormChangeValues();
  }

  onChangeCompanyId(data: ISelection) {
    this.form.get(CommonConstant.FIELD.COMPANY_ID)?.setValue(data.value);
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.COMPANY_NOT_FOUND);
  }

  onChangeRoleId(data: ISelection) {
    this.form.get(CommonConstant.FIELD.ROLE_ID)?.setValue(data.value);
    this.clearFeedbackErrors(ErrorConstant.ERROR_FLAG.ROLE_NOT_FOUND);
  }
}
